import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import { urlendpoint } from "../constants";

const firebaseConfig = {
  apiKey: "AIzaSyA_V4kbrA5mPw4s3zGF2WEYKI9DxqAG8Cc",
  authDomain: "cosbiome-blog.firebaseapp.com",
  projectId: "cosbiome-blog",
  storageBucket: "cosbiome-blog.appspot.com",
  messagingSenderId: "428151507978",
  appId: "1:428151507978:web:acb1ecf86e2b36e12fa3f3",
  measurementId: "G-B1D5P02XFC",
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

export const getToken = async () => {
  try {
    let token = await messaging.getToken();

    if (token) {
      await fetch(`${urlendpoint}/strapi-fcm/users/tokens`, {
        method: "POST",
        body: JSON.stringify({ token, publishedAt: new Date().toISOString() }),
        headers: {
          "Content-Type": "application/json",
        },
      });
    }

    return token;
  } catch (error) {
    return "sin token";
  }
};
