/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import logocosbiome from "../assets/img/logocosbiome.png";
import { GlobalContext } from "../context/GlobalContext";

const LayoutComponent = ({ children }: { children: any }) => {
  const [isShowNav, setIsShowNav] = useState(true);

  const {
    isLogged,
    funcs: { setIsLogged, setIsPopupOpenLogin },
  } = useContext(GlobalContext);

  return (
    <div>
      <nav className="navbar sticky-top navbar-expand-lg bg-light justify-content-center">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              src={logocosbiome}
              alt="logocosbiome"
              style={{
                width: "65px",
                height: "65px",
                borderRadius: "50%",
              }}
            />
          </Link>
          <button
            onClick={() => setIsShowNav(!isShowNav)}
            className="navbar-toggler"
            type="button"
            // data-bs-toggle="collapse"
            // data-bs-target="#navbarNavAltMarkup"
            // aria-controls="navbarNavAltMarkup"
            // aria-expanded="false"
            // aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isShowNav ? "" : "show"}`}
            id="navbarNavAltMarkup"
          >
            {/* <div
              className="navbar-nav"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            > */}
            <div
              style={{
                width: "100%",
              }}
            ></div>
            <div
              className="entries-nav-blog"
              // style={{
              //   display: "flex",
              //   width: "100%",
              //   justifyContent: "center",
              //   alignItems: "center",
              //   flexDirection:
              //     window.screen.availWidth < 992 ? "column" : "row",
              // }}
            >
              <Link
                className="nav-link active"
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
                aria-current="page"
                to="/"
              >
                Blog
              </Link>

              {/* <a className="nav-link" href="#">
                  Sitio web
                </a> */}
              <a
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
                className="nav-link"
                href="https://cosbiome.com"
              >
                Visita Nuestro sitio web
              </a>
              <Link
                style={{
                  marginRight: "10px",
                }}
                onClick={() => {
                  if (isLogged) {
                    setIsLogged(false);
                    localStorage.setItem("isLogged", "false");
                    localStorage.removeItem("token");
                    sessionStorage.removeItem("isPoppuOpenFirstTime");
                  } else {
                    setIsPopupOpenLogin(true);
                  }
                }}
                className="nav-link sessionbutton"
                aria-current="page"
                to="/"
              >
                {isLogged ? "Cerrar Sesión" : "Iniciar Sesión"}
              </Link>
            </div>
            <div
              className="sessionbutton"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <SocialIcon
                url="https://facebook.com/cosbiome"
                style={{
                  marginRight: "10px",
                }}
              />
              <SocialIcon
                url="https://instagram.com/cosbiome"
                style={{
                  marginRight: "10px",
                }}
              />
              <SocialIcon
                url="https://tiktok.com/@cosbiome"
                aria-setsize={3}
                style={{
                  marginRight: "10px",
                }}
              />
              <SocialIcon
                url="https://pinterest.com/cosbiomegdl"
                style={{
                  marginRight: "10px",
                }}
              />
              <SocialIcon
                style={{
                  marginRight: "10px",
                }}
                url="mailto:contacto@cosbiome.mx"
              />
            </div>
            {/* </div> */}
          </div>
        </div>
      </nav>
      {children}
      <footer className="site-footer clearfix">
        <a href="#top" id="back-to-top" className="back-top"></a>
        <div className="text-center">
          <a href="https://cosbiome.com">COSBIOME</a> &copy; 2022 - desarrolado
          por {"Pablo Rizo "}
        </div>
      </footer>
    </div>
  );
};

export default LayoutComponent;
