/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal } from "antd";
import { createContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import cosbiome from "../assets/img/cosbiome.png";
import { urlendpoint } from "../constants";
import useHttp from "../hooks/useHttp";
import { getToken } from "../utils/firebase";

interface IGlobalContextData {
  isLogged: boolean;
  isPopupOpenRegis: boolean;
  isPopupOpenLogin: boolean;
  funcs: {
    setIsLogged: (isLogged: boolean) => void;
    setIsPopupOpenRegis: (isPopupOpen: boolean) => void;
    setIsPopupOpenLogin: (isPopupOpen: boolean) => void;
  };
}

export const GlobalContext = createContext<IGlobalContextData>({
  isLogged: false,
  isPopupOpenRegis: false,
  isPopupOpenLogin: false,
  funcs: {
    setIsLogged: () => {},
    setIsPopupOpenRegis: () => {},
    setIsPopupOpenLogin: () => {},
  },
});

export const GlobalProvider = ({ children }: any) => {
  const [isLogged, setIsLogged] = useState(false);
  const [isPopupOpenRegis, setIsPopupOpenRegis] = useState(false);
  const [isPopupOpenLogin, setIsPopupOpenLogin] = useState(false);

  const { login } = useHttp();

  useEffect(() => {
    getToken();

    const isPoppuOpenFirstTime = sessionStorage.getItem("isPoppuOpenFirstTime");
    const isLoggedlocal = localStorage.getItem("isLogged");

    if (isLoggedlocal && isLoggedlocal === "true") {
      updateTokenUser();
      setIsLogged(true);
    }

    if (
      !isPoppuOpenFirstTime &&
      !isLogged &&
      !isLoggedlocal &&
      isLoggedlocal !== "true"
    ) {
      setIsPopupOpenRegis(true);
      sessionStorage.setItem("isPoppuOpenFirstTime", "true");
    }
  }, []);

  const updateTokenUser = async () => {
    try {
      const token = await getToken();

      if (token !== "sin token") {
        await fetch(
          `${urlendpoint}/api/users/${localStorage.getItem("idUser")}`,
          {
            method: "PUT",
            body: JSON.stringify({ tokenpush: token }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        isLogged,
        isPopupOpenRegis,
        isPopupOpenLogin,
        funcs: {
          setIsLogged,
          setIsPopupOpenRegis,
          setIsPopupOpenLogin,
        },
      }}
    >
      <Modal
        onCancel={() => setIsPopupOpenLogin(false)}
        width={1000}
        centered
        visible={isPopupOpenLogin}
        footer={null}
      >
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={async (values) => {
            try {
              const response = await login("auth/local", {
                identifier: values.email,
                password: values.password,
              });

              localStorage.setItem("token", response.token);
              localStorage.setItem("idUser", response.user.id);
              localStorage.setItem("isLogged", "true");
              setIsLogged(true);
              setIsPopupOpenRegis(false);
              setIsPopupOpenLogin(false);

              Swal.fire({
                title: "Bienvenido",
                text: "¡Has iniciado sesión correctamente!",
                icon: "success",
              });
            } catch (error) {
              Swal.fire({
                title: "Error",
                text: "Ocurrió un error al iniciar sesión contraseña o correo incorrectos",
                icon: "error",
              });
            }
          }}
          onFinishFailed={() => {}}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={cosbiome} width="200px" alt="cosbiome" className="mb-5" />
          </div>

          <Form.Item
            label="Correo electrónico"
            name="email"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            style={{
              width: "100%",
            }}
            label="Contraseña"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Button
              onClick={() => {
                setIsPopupOpenLogin(false);
                setIsPopupOpenRegis(true);
              }}
              type="link"
            >
              Registrarte
            </Button>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Inicia sesión
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        onCancel={() => setIsPopupOpenRegis(false)}
        width={1000}
        centered
        visible={isPopupOpenRegis}
        footer={null}
      >
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={async (values) => {
            try {
              if (values.password !== values.passwordconfirm) {
                Swal.fire({
                  title: "Error",
                  text: "Las contraseñas no coinciden",
                  icon: "error",
                });
                return;
              }

              const response = await login("auth/local/register", {
                email: values.email,
                password: values.password,
                username: values.email.split("@")[0],
              });

              localStorage.setItem("token", response.token);
              localStorage.setItem("idUser", response.user.id);
              localStorage.setItem("isLogged", "true");
              setIsLogged(true);
              setIsPopupOpenRegis(false);
              setIsPopupOpenLogin(false);

              Swal.fire({
                title: "Registro exitoso",
                text: "Ahora puedes iniciar sesión",
                icon: "success",
              });
            } catch (error) {
              Swal.fire({
                title: "Error al registrarte",
                text: "Puede que el correo ya este registrado o estemos experimentando problemas",
                icon: "error",
              });
            }
          }}
          onFinishFailed={() => {}}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={cosbiome} width="200px" alt="cosbiome" className="mb-5" />

            <p
              className="mt-2"
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              ¡Suscríbete a nuestro boletín informativo!
              <br /> Recibe nuestros artículos informativos, noticias y ofertas
              antes que nadie.
            </p>
          </div>

          <Form.Item
            label="Correo electrónico"
            name="email"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirmar contraseña"
            name="passwordconfirm"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Button
              onClick={() => {
                setIsPopupOpenRegis(false);
                setIsPopupOpenLogin(true);
              }}
              type="link"
            >
              Inciar sesión
            </Button>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Registrarse
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {children}
    </GlobalContext.Provider>
  );
};
