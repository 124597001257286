import { Link } from "react-router-dom";
import { urlendpoint } from "../constants";

import { IArticuloBlog } from "../interfaces/IArticuloBlog";

interface IPropsCardArticleBlog {
  article: IArticuloBlog;
}

const CardArticleBlog = ({ article }: IPropsCardArticleBlog) => {
  return (
    <div className="grid-item">
      <article className="post">
        <Link to={`/articulos/${article.attributes.url}`}>
          <img
            src={`${urlendpoint}${article.attributes.cardimg.data.attributes.url}`}
            alt=""
          />
        </Link>
        <div className="wrapgriditem">
          <header className="post-header">
            <h2 className="post-title">
              <Link to={`/articulos/${article.attributes.url}`}>
                {article.attributes.title}
              </Link>
            </h2>
          </header>
          <section className="post-excerpt">
            <p>
              {article.attributes.summarycard}{" "}
              <Link to={`/articulos/${article.attributes.url}`}>&raquo;</Link>
            </p>
          </section>
          <footer className="post-meta">
            <img
              className="author-thumb"
              src={`${urlendpoint}${article.attributes.autor.data.attributes.profilephoto.data.attributes.url}`}
              alt="David"
            />
            <Link to={`/articulos/${article.attributes.url}`}>
              {article.attributes.autor.data.attributes.username}
            </Link>
            <time className="post-date" dateTime="2016-12-18">
              {new Date(article.attributes.createdAt).toLocaleString()}
            </time>
          </footer>
        </div>
      </article>
    </div>
  );
};

export default CardArticleBlog;
