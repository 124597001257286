/* eslint-disable react-hooks/exhaustive-deps */
import AwesomeSlider from "react-awesome-slider";
import CardArticleBlog from "../components/CardArticleBlog";
import useHttp from "../hooks/useHttp";
import { useEffect, useState } from "react";
import { IArticuloBlog } from "../interfaces/IArticuloBlog";
import LoadingCosbiome from "../components/LoadingCosbiome";
import { useNavigate } from "react-router-dom";
import { urlendpoint } from "../constants";

const Home = () => {
  const [articles, setArticles] = useState<IArticuloBlog[]>([]);

  const { get } = useHttp();
  const history = useNavigate();

  useEffect(() => {
    hanldeGetArticles();
  }, []);

  const hanldeGetArticles = async () => {
    const articlesDB: { data: IArticuloBlog[] } = await get(
      "articulos?populate=deep"
    );

    setArticles(articlesDB.data);
  };

  return (
    <div className="">
      <header className="main-header ">
        {articles.length === 0 ? (
          <div
            style={{
              background: "#3CB4E5",
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingCosbiome />
          </div>
        ) : (
          <>
            {" "}
            <div className="vertical">
              <AwesomeSlider
                animation="fallAnimation"
                style={{
                  width: "100%",
                  height: "80vh",
                }}
              >
                {articles.map((article) => (
                  <div
                    key={article.id + "slider"}
                    style={{
                      background: "#3CB4E5",
                    }}
                  >
                    <img
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history(`/articulos/${article.attributes.url}`);
                      }}
                      src={`${urlendpoint}${article.attributes.frontpagepostimg.data.attributes.url}`}
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  </div>
                ))}
              </AwesomeSlider>
            </div>
            <a
              className="scroll-down icon-arrow-left"
              href="#content"
              data-offset="-45"
            >
              <span className="hidden">Scroll Down</span>
            </a>
          </>
        )}
      </header>

      <main id="content" className="content" role="main">
        <div className="wraps">
          <div className="grid row">
            {articles.map((article) => (
              <CardArticleBlog key={article.id + "card"} article={article} />
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
