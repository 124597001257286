/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useHttp from "../../hooks/useHttp";

import shadow from "../../assets/img/shadow.png";
import { useParams } from "react-router-dom";
import { IArticuloBlog } from "../../interfaces/IArticuloBlog";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import LoadingCosbiome from "../../components/LoadingCosbiome";
import { urlendpoint } from "../../constants";
import Swal from "sweetalert2";

const ArticuloDetalle = () => {
  const [article, setArticle] = useState<IArticuloBlog>();

  const { get, update } = useHttp();
  const params = useParams();

  useEffect(() => {
    setTimeout(() => {}, 750);

    handleGetArticle();
  }, []);

  const handleGetArticle = async () => {
    try {
      const articleDB: { data: IArticuloBlog[] } = await get(
        `articulos?filters[$and][0][url][$eq]=${params.id}&populate=deep`
      );

      await update(`articulos/${articleDB.data[0].id}`, {
        views: articleDB.data[0].attributes.views + 1,
      });

      setArticle(articleDB.data[0]);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No se pudo cargar el artículo",
        icon: "error",
      });
    }
  };

  if (!article) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingCosbiome />
      </div>
    );
  }

  return (
    <div className="post-template">
      <header
        className="main-header post-head"
        style={{
          backgroundImage: `url(${urlendpoint}${
            article!.attributes.frontpagepostimg.data.attributes.url
          })`,
        }}
      >
        <div className="verticaldetail">
          <div className="main-header-content inner">
            <h1 className="post-title">{article.attributes.title}</h1>
            <div className="entry-title-divider">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <section className="post-meta">
              <time className="post-date" dateTime="2015-12-13">
                {new Date(article.attributes.createdAt).toLocaleString()}
              </time>
            </section>
          </div>
        </div>
      </header>

      <main id="content" className="content" role="main">
        <div className="wraps">
          <img src={shadow} className="wrapshadow" alt="" />
          <article className="post featured">
            <ReactMarkdown
              className="post-content mb-5"
              rehypePlugins={[rehypeRaw]}
            >
              {article!.attributes.content}
            </ReactMarkdown>
            <footer className="post-footer ">
              <figure className="author-image">
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${urlendpoint}${article.attributes.autor.data.attributes.profilephoto.data.attributes.url})`,
                  }}
                >
                  <span className="hidden">David's Picture</span>
                </div>
              </figure>
              <section className="author">
                <h4>
                  <a href="/author/ghosty/">
                    {article.attributes.autor.data.attributes.username}
                  </a>
                </h4>
                <p>{article.attributes.autor.data.attributes.description}</p>
                <div className="author-meta">
                  <span className="author-location icon-location">
                    Guadalajara Jal. MX
                  </span>
                  {/* <span className="author-link icon-link">
                    <a href="https://www.wowthemes.net">
                      https://www.wowthemes.net
                    </a>
                  </span> */}
                </div>
              </section>
              <section className="share">
                <h4>Comparte este post</h4>
                <a
                  className="icon-twitter"
                  href="https://twitter.com/intent/tweet?text=Once%20Upon%20a%20Time&amp;url=#"
                  //   onclick="window.open(this.href, 'twitter-share', 'width=550,height=235');return false;"
                >
                  <span className="hidden">Twitter</span>
                </a>
                <a
                  className="icon-facebook"
                  href="https://www.facebook.com/sharer/sharer.php?u=#"
                  //   onclick="window.open(this.href, 'facebook-share','width=580,height=296');return false;"
                >
                  <span className="hidden">Facebook</span>
                </a>
              </section>
            </footer>
            <div id="disqus_thread"></div>
          </article>
        </div>
      </main>
    </div>
  );
};

export default ArticuloDetalle;
