import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-awesome-slider/dist/styles.css";
import "antd/dist/antd.css";
import reportWebVitals from "./reportWebVitals";
import LayoutComponent from "./components/LayoutComponent";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ArticuloDetalle from "./pages/arituclos/ArticuloDetalle";
import { GlobalProvider } from "./context/GlobalContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalProvider>
        <LayoutComponent>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/articulos/:id" element={<ArticuloDetalle />} />
          </Routes>
        </LayoutComponent>
      </GlobalProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
